import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ThemeContext } from './contexts/theme';
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Resume from './components/Resume/Resume'; // Import the Resume component
import { Settings } from 'lucide-react'; // Importing the icon
import './App.css';

const App = () => {
  const [{ themeName }] = useContext(ThemeContext);
  const location = useLocation(); // Get location from the Router
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust loading time as needed

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="animate-spin">
          <Settings className="text-white" size={80} />
        </div>
      </div>
    );
  }

  return (
    <div id='top' className={`${themeName} app`}>
      {location.pathname !== '/resume' && <Header />}

      <main>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={
            <>
              <About />
              <Projects />
              <Skills />
              <Contact />
            </>
          } />
          <Route path='/resume' element={<Resume />} /> {/* Add this line for Resume */}
        </Routes>
      </main>
                                   
      <Footer />
    </div>
  );
}

export default App;
