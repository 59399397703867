import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <p className='footer__text'>
      &copy; {new Date().getFullYear()} . All rights reserved.
    </p>
    <p className='footer__text'>
      <a href='https://www.yourwebsite.com' className='link footer__link'>
        www.Saudhussain.in
      </a> | <a href='mailto:contact@yourcompany.com' className='link footer__link'>
        Saudhusayn@gmail.com
      </a>
    </p>
  </footer>
)

export default Footer
