import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../contexts/theme";
import { FaEnvelope, FaLinkedin, FaGithub, FaWhatsapp } from "react-icons/fa";

const Contact = () => {
  const [{ themeName }] = useContext(ThemeContext);
  const [status, setStatus] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (status !== "") {
      const timer = setTimeout(() => setStatus(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://formspree.io/f/mkgnwral", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setFormData({ name: "", email: "", message: "" });
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setStatus("ERROR");
    }
  };

  const inputClass = `w-full px-3 py-2 rounded-md focus:outline-none focus:ring-2 ${
    themeName === "dark"
      ? "bg-gray-700 text-gray-100 border-gray-600 focus:ring-blue-500"
      : "bg-gray-100 text-gray-800 border-gray-300 focus:ring-blue-500"
  }`;

  const labelClass = `block text-sm font-medium mb-1 ${
    themeName === "dark" ? "text-gray-300" : "text-gray-700"
  }`;

  return (
    <section className={`py-8 md:mt-10 `} id="contact">
      <div className="container mx-auto px-4 max-w-3xl">
        <div className="md:flex md:items-start md:justify-between">
          <div className="md:w-2/5 mb-6 md:mb-0">
            <h2 className="text-2xl font-bold mb-2 text-[#2978b5]">
              Get in Touch
            </h2>
            <p className="mb-4 text-sm">Feel free to reach out!</p>
            <div className="flex space-x-4 sm:space-x-6">
              <a
                href="mailto:saudhusayn@gmail.com"
                className="text-gray-400 hover:text-purple-400 transition duration-300"
              >
                <FaEnvelope className="text-2xl sm:text-3xl" />
              </a>
              <a
                href="https://www.linkedin.com/in/saud-h-43a584199"
                className="text-gray-400 hover:text-purple-400 transition duration-300"
              >
                <FaLinkedin className="text-2xl sm:text-3xl" />
              </a>
              <a
                href="https://#"
                className="text-gray-400 hover:text-purple-400 transition duration-300"
              >
                <FaGithub className="text-2xl sm:text-3xl" />
              </a>
              <a
                href="https://wa.me/+971527272685"
                className="text-gray-400 hover:text-purple-400 transition duration-300"
              >
                <FaWhatsapp className="text-2xl sm:text-3xl" />
              </a>
            </div>
          </div>
          <div className="md:w-3/5">
            <form onSubmit={handleSubmit} className="space-y-3">
              <div>
                <label htmlFor="name" className={labelClass}>
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className={inputClass}
                />
              </div>
              <div>
                <label htmlFor="email" className={labelClass}>
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className={inputClass}
                />
              </div>
              <div>
                <label htmlFor="message" className={labelClass}>
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="3"
                  className={inputClass}
                ></textarea>
              </div>
              <button
                type="submit"
                className={`w-full font-bold py-2 px-4 rounded-md transition duration-300 ${
                  themeName === "dark"
                    ? "bg-blue-600 text-white hover:bg-blue-700"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
              >
                Send Message
              </button>
            </form>
            {status && (
              <div className="mt-3 text-center text-sm font-semibold">
                {status === "SUCCESS" && (
                  <p className="text-green-500">Message sent successfully!</p>
                )}
                {status === "ERROR" && (
                  <p className="text-red-500">
                    Error sending message. Please try again.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
