import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ThemeProvider } from './contexts/theme'; // Import Router
import './index.css';

render(
  <ThemeProvider>
    <Router> 
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
