import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { about } from "../../portfolio";
import "./About.css";

const About = () => {
  const { name, description, social } = about;
  const roles = ["Full Stack", "Frontend", "Backend", "App"];
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentRoleIndex((prevIndex) => (prevIndex + 1) % roles.length);
    }, 2500);
    return () => clearInterval(intervalId);
  }, []);

  const handleResumeClick = () => {
    navigate("/resume");
  };

  return (
    <div className="about center">
      <div className="about__greeting">
        {name && (
          <h1>
            Hi, I am <span className="about__name">{name}</span>
            <span>.</span>
          </h1>
        )}
      </div>
      <h2 className="about__role">
        <span
          className={`animated-role ${currentRoleIndex !== null ? "show" : ""}`}
        >
          {roles[currentRoleIndex]} Developer
        </span>
      </h2>
      <p className="about__desc">{description && description}</p>
      <div className="about__contact center">
        <button
          type="button"
          className="btn btn--outline animate-shake"
          onClick={handleResumeClick}
        >
          Resume
        </button>
        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label="github"
                className="link link--icon"
              >
                <GitHubIcon />
              </a>
            )}
            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label="linkedin"
                className="link link--icon"
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default About;
